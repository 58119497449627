<reporter-modal
  [modalTitle]="moduleName + ' Audit Logs'"
  modalClasses="modal-xxl"
  [show]="auditHistory !== undefined"
  [noFooter]="false"
  (onClose)="close.emit()">
  <div role="modal-body"
       class="audit-history">
    <div *ngIf="auditHistory"
         class="contents">
      <div *ngIf="createdDate || modifiedTs"
           class="information row">
        <div *ngIf="createdDate && createdUserId"
             class="col-6">
          <strong>Created on </strong>
          {{ createdDate }} <strong>by User</strong> {{ createdUserId }}
        </div>
        <div *ngIf="modifiedTs && modifiedByUserId"
             class="col-6">
          <strong>Last Edited on </strong>
          {{ modifiedTs }} <strong>by
          User</strong> {{ modifiedByUserId }}
        </div>
        <hr class="w-100">
      </div>
      <div class="results">
        <div *ngIf="auditHistory.length === 0"
             class="w-100 px-3 text-info">No records found.
        </div>
        <div *ngIf="auditHistory.length > 0"
             class="results-table mb-3">
          <div class="results-count">{{ totalRecords }} records found</div>
          <div class="results-header"
               [class.pr-0]="noScrollbar">
            <div class="col-2">Date Changed</div>
            <div class="col-1">User</div>
            <div class="col-2">Field</div>
            <div class="col-3">Old Value</div>
            <div class="col-3">New Value</div>
          </div>
          <div class="results-body pb-2"
               #resultsBody
               infiniteScroll
               [scrollWindow]="false"
               [infiniteScrollDistance]="2"
               [infiniteScrollThrottle]="50"
               [infiniteScrollDisabled]="totalPages < pageNumber"
               (scrolled)="loadNextPage()">
            <div *ngFor="let record of auditHistory; let index = index;"
                 class="result-row">
              <div class="result-cell col-2"
                   [attr.data-text]="record.modifiedTs">{{ record.modifiedTs }}</div>
              <div class="result-cell col-1"
                   [attr.data-text]="record.userId">{{ record.userId }}</div>
              <div class="result-cell col-2"
                   [attr.data-text]="record.field">{{ record.field }}</div>
              <div class="result-cell col-3"
                   [attr.data-text]="record.oldValue">{{ record.oldValue }}</div>
              <div class="result-cell col-3"
                   [attr.data-text]="record.newValue">{{ record.newValue }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div role="modal-footer">
    <div class="d-flex flex-row mx-0 px-3 justify-content-end">
      <button class="btn btn-action"
              type="button"
              (click)="close.emit()">Close
      </button>
    </div>
  </div>
</reporter-modal>
