<reporter-modal [show]="show"
                [displayCloseBtn]="true"
                [noFooter]="true"
                modalTitle="Vehicle Lookup"
                modalClasses="modal-lg"
                (onClose)="close.emit(true)">
  <div role="modal-body">
    <div class="content">
      <reporter-notifications [clearOnNew]="true"></reporter-notifications>
      <div class="vehicles-search"
           [ngClass]="{'overflow-hidden': !vehiclesSearchOpen}">
        <div class="vehicle-search-control"
             (click)="vehiclesSearchOpen = !vehiclesSearchOpen">
          <div>
            <reporter-icon *ngIf="vehiclesSearchOpen"
                           icon="Expanded"></reporter-icon>
            <reporter-icon *ngIf="!vehiclesSearchOpen"
                           icon="Collapsed"></reporter-icon>
            <span class="pl-3">Vehicles Search Criteria</span>
          </div>
        </div>
        <form role="form"
              #form="ngForm"
              class="px-2">
          <div class="search-form row mx-0 py-2"
               *ngIf="vehiclesSearchOpen">
            <div class="form-group col-12 col-md-6">
              <label>VIN</label>
              <input type="text"
                     name="vin"
                     placeholder="VIN"
                     [(ngModel)]="searchModel['vin']"
                     class="form-control"/>
            </div>
            <div class="form-group col-12 col-md-6">
              <label>Plate Number</label>
              <input type="text"
                     name="plateNumber"
                     placeholder="Plate Number"
                     [(ngModel)]="searchModel['plateNumber']"
                     class="form-control"/>
            </div>
            <div class="form-group col-12 col-md-6">
              <label>Vehicle #/ID</label>
              <input type="text"
                     name="number"
                     placeholder="Number"
                     [(ngModel)]="searchModel['number']"
                     class="form-control"/>
            </div>
            <div class="form-group col-12 col-md-6">
              <label>Make</label>
              <input type="text"
                     name="make"
                     placeholder="Make"
                     [(ngModel)]="searchModel['make']"
                     class="form-control"/>
            </div>
            <div class="form-group col-12 col-md-6">
              <label>Model</label>
              <input type="text"
                     name="model"
                     placeholder="Model"
                     [(ngModel)]="searchModel['model']"
                     class="form-control"/>
            </div>
            <div class="form-group col-12 col-md-6">
              <label>Year</label>
              <input type="text"
                     name="year"
                     placeholder="Year"
                     [(ngModel)]="searchModel['year']"
                     class="form-control"/>
            </div>
            <div class="form-group col-12 col-md-6 pt-4">
              <div class="form-group search-input mb-0">
                <div class="custom-control custom-checkbox">
                  <input type="checkbox"
                         name="includeDescendants"
                         id="includeDescendants"
                         class="custom-control-input"
                         [(ngModel)]="searchModel['includeDescendants']"/>
                  <label class="custom-control-label"
                         for="includeDescendants">Include Descendants</label>
                </div>
              </div>
            </div>

            <div class="d-flex col-12 justify-content-end align-items-center py-2 py-lg-0 mt-5">
              <button type="button"
                      class="btn btn-action"
                      data-test-id="search-form-clear"
                      (click)="resetForm()">CLEAR
              </button>
              <button type="submit"
                      class="btn btn-action ml-3"
                      (click)="search()">SEARCH
              </button>
            </div>
          </div>
        </form>
      </div>
      <div *ngIf="vehicles"
           class="results-count">{{ totalRecords }} record{{ totalRecords !== 1 ? 's' : '' }} found
      </div>

      <div class="search-results"
           *ngIf="vehicles?.length"
           infiniteScroll
           [scrollWindow]="false"
           [infiniteScrollDistance]="1"
           [infiniteScrollThrottle]="50"
           [infiniteScrollDisabled]="pageNumber >= totalPages"
           (scrolled)="loadNextPage()">
        <div class="results-list">
          <div *ngFor="let vehicle of vehicles; let index = index;"
               class="list-group-item"
               [class.zebra]="index % 2 == 1">
            <div class="reporting-location cursor-pointer col"
                 (click)="getDetails(vehicle)"
                 (mouseenter)="mouseOver(vehicle)"
                 (mouseleave)="unsetTimeOut(vehicle)">
              <div class="d-flex align-middle justify-content-between">
                <div>VIN: {{ vehicle.vin }}</div>
                <button
                  class="btn btn-action py-0"
                  *ngIf="vehicle.id === details?.id"
                  (click)="select(vehicle)">
                  Select
                </button>
              </div>
              <reporter-vehicle-details *ngIf="vehicle.id === details?.id"
                                        [vehicle]="details"></reporter-vehicle-details>
            </div>
          </div>
        </div>
      </div>

      <div class="results">

      </div>
    </div>
  </div>
</reporter-modal>
