import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';

@Component({
  selector: 'reporter-modal',
  templateUrl: './modal.component.html',
  styleUrls: [ './modal.component.scss' ]
})
export class ModalComponent implements OnInit {

  @Input() displayCloseBtn = true;
  @Input() noFooter = false;
  @Input() modalTitle = '';
  @Input() show = false;
  @Input() modalClasses = '';

  @Output() onClose = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit() {
  }

  close() {
    this.show = false;
    this.onClose.emit(true);
  }

}
