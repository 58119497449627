<reporter-modal
  [modalTitle]="'Incident Answers Audit Logs'"
  modalClasses="modal-xxl"
  [show]="auditHistory !== undefined"
  [noFooter]="true"
  (onClose)="close.emit()">
  <div role="modal-body"
       class="audit-history">
    <div *ngIf="auditHistory"
         class="contents">
      <div class="results">
        <div *ngIf="auditHistory.length === 0"
             class="w-100 px-3 text-info">No records found.
        </div>
        <div *ngIf="auditHistory.length > 0"
             class="results-table mb-3">
          <div class="results-count">{{ totalRecords }} records found</div>
          <div class="results-header"
               [class.pr-0]="noScrollbar">
            <div class="col-2">Date Changed</div>
            <div class="col-2">Question</div>
            <div class="col-1">Sequence</div>
            <div class="col-3">Answer Was</div>
            <div class="col-3">Answer Became</div>
            <div class="col-1">User</div>
          </div>
          <div class="results-body pb-2"
               #resultsBody
               infiniteScroll
               [scrollWindow]="false"
               [infiniteScrollDistance]="2"
               [infiniteScrollThrottle]="50"
               [infiniteScrollDisabled]="totalPages < pageNumber"
               (scrolled)="loadNextPage()">
            <div *ngFor="let record of auditHistory; let index = index;"
                 class="result-row">
              <div class="result-cell col-2"
                   [attr.data-text]="record.modifiedTs">{{ record.modifiedTs }}</div>
              <div class="result-cell col-2"
                   [attr.data-text]="record.questionKey">{{ record.questionKey }}</div>
              <div class="result-cell col-1"
                   [attr.data-text]="record.sequenceNbr">{{ record.sequenceNbr }}</div>
              <div class="result-cell col-3"
                   [attr.data-text]="record.newAnswer">{{ record.oldAnswer }}</div>
              <div class="result-cell col-3"
                   [attr.data-text]="record.newAnswer">{{ record.newAnswer }}</div>
              <div class="result-cell col-1"
                   [attr.data-text]="record.userId">{{ record.userId }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</reporter-modal>
