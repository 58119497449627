<div class="input-group">
  <input type="text"
         class="form-control"
         #field
         [ngModel]="selectedPolicy?.policyNumber"
         (blur)="onTouched()"
         readonly>
  <div class="input-group-append">
    <button #button
            class="btn btn-action"
            (click)="showPolicies()"
            type="button"
            [disabled]="policies?.length < 2">
      <reporter-icon icon="Search"></reporter-icon>
    </button>
  </div>
</div>

<reporter-modal *ngIf="showList"
                [show]="showList"
                modalTitle="Select Policy"
                (onClose)="showList = false"
                [noFooter]="true"
                modalClasses="modal-xl">
  <div role="modal-body"
       class="policies-modal px-0">
    <div *ngFor="let policy of policies"
         class="row mx-0">
    </div>
    <div class="search-results"
         *ngIf="policies?.length">
      <div class="results-list">
        <div *ngFor="let policy of policies; let index = index"
             class="list-group-item py-1 px-0"
             [class.zebra]="index % 2 == 1">
          <div class="col-6 col-md-3 col-lg-2">{{ policy.policyNumber }}</div>
          <div class="col">{{ jurisdictionAdditionalInfo(policy) }}</div>
          <div class="col-auto">
            <button
              (click)="writeValue(policy); showList = false;"
              type="button"
              class="btn btn-action btn-sm">
              SELECT
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</reporter-modal>
